exports.components = {
  "component---src-pages-budgettrips-js": () => import("./../../../src/pages/budgettrips.js" /* webpackChunkName: "component---src-pages-budgettrips-js" */),
  "component---src-pages-drp-js": () => import("./../../../src/pages/drp.js" /* webpackChunkName: "component---src-pages-drp-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-profit-01-js": () => import("./../../../src/pages/profit01.js" /* webpackChunkName: "component---src-pages-profit-01-js" */),
  "component---src-pages-teonee-js": () => import("./../../../src/pages/teonee.js" /* webpackChunkName: "component---src-pages-teonee-js" */),
  "component---src-pages-x-4-b-js": () => import("./../../../src/pages/x4b.js" /* webpackChunkName: "component---src-pages-x-4-b-js" */)
}

